<template>
  <div>
    <div class="title">
      <div class="login">忘记密码</div>
      <div class="register" @click="$router.push({ path: 'login' })">
        直接登录
      </div>
    </div>
    <div class="content">
      <div class="iphoneNumber">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="user"
          center
          clearable
          placeholder="请输入手机号码"
          label-width="250px"
        >
        </van-field>
      </div>
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="code"
          center
          clearable
          placeholder="请输入短信验证码"
          label-width="250px"
        >
          <template #right-icon>
            <div class="VerificationCode">获取验证码</div>
          </template>
        </van-field>
      </div>
      <div class="submit">下一步</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      code: "",
      show: false
    };
  },
  computed: {},
  methods: {
    submit() {}
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 31px;
  font-size: 33px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  padding-top: 88px;
  text-align: center;
  .login {
    // text-align: center;
    font-size: 33px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  .register {
    position: absolute;
    right: 63px;
    top: 95px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.content {
  padding-top: 120px;
  margin: 0 65px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .iphoneNumber {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    .inputPhoneNumber {
      height: 100%;
      margin-left: 40px;
    }
  }
  .code {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    margin-top: 52px;
    .inputCode {
      height: 100%;
      margin-left: 40px;
    }
    .VerificationCode {
      width: 174px;
      height: 51px;
      border: 1px solid #d2d2d2;
      border-radius: 26px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
  .submit {
    width: 100%;
    height: 90px;
    background: linear-gradient(-30deg, #323234, #464646);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    margin-top: 52px;

    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
